<div class="grid-container" *ngIf="isInitialized && rides.length === 0">
    {{ 'upcoming-rides-none-planned' | translate }}
</div>

<div class="grid-container" *ngIf="rides.length > 0">
    <div class="grid-row" 
      *ngFor="let ride of rides" 
      (click)="selectRide(ride.id)"
      [ngClass]="{'selected': selectedRide === ride.id}">
        <div class="grid-item">
            <div>{{ 'time' | translate }} </div>
            <div>{{ ride.pickupTime +'Z' | date:'HH:mm' }}</div>
        </div>
        <div class="grid-item" *ngIf="ride.to">
            <div>{{ 'upcoming-rides-to' | translate }} </div>
            <div>{{ ride.to.name }}</div>
        </div>        
        <div class="grid-item" *ngIf="!ride.to">
            <div>{{ 'upcoming-rides-unknown-destination' | translate }}</div>
        </div>
        <div class="grid-item">
            <div *ngIf="statusOnItsWay(ride) && ride.etaOfTaxi !== null" class="status on-its-way">{{ 'upcoming-rides-on-its-way' | translate }} - ETA: {{ride.etaOfTaxi}} min.</div>
            <div *ngIf="statusOnItsWay(ride) && ride.etaOfTaxi == null" class="status on-its-way">{{ 'upcoming-rides-on-its-way' | translate }}</div>
            <div *ngIf="statusTaximeterStarted(ride)" class="status taximeter-started">{{ 'upcoming-rides-taximeter-started' | translate }}</div>
            <div *ngIf="statusInVain(ride)" class="status in-vain">{{ 'upcoming-rides-in-vain' | translate }}</div>
            <div *ngIf="statusNoShow(ride)" class="status in-vain">{{ 'upcoming-rides-no-show' | translate }}</div>
            <div *ngIf="statusInVain(ride)" class="status text">{{ inVainContactText }}</div>
        </div>  
        <div class="grid-item" *ngIf="ride.roomNumber">
            <div>{{ 'upcoming-rides-room-number' | translate }} </div>
            <div>{{ ride.roomNumber }}</div>
        </div>  
        <div class="grid-item" *ngIf="ride.taxiCarId">
            <div>{{ 'upcoming-rides-taxi-car-id' | translate }} </div>
            <div>{{ ride.taxiCarId }}  </div>
        </div>  
        <div class="grid-item" *ngIf="ride.guestName">
            <div>{{ 'upcoming-rides-guest-name' | translate }} </div>
            <div>{{ ride.guestName }}</div>
        </div>  
        <div class="grid-item" *ngIf="!statusTaximeterStarted(ride) 
            && !statusCancelled(ride) 
            && !statusCompleted(ride) 
            && !statusUnknown(ride) 
            && !statusInVain(ride)  
            && !statusNoShow(ride)">
            <button mat-stroked-button
                    color="danger"
                    class="button"
                    (click)="cancelRide(ride)">
                {{'cancelTaxi' | translate}}
            </button>
        </div>
    </div>
</div>