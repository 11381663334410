import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { IPriceInfo } from 'src/app/services/models/price-info.model';
import { PriceService } from 'src/app/services/price.service';

@Component({
  selector: 'app-new-price',
  templateUrl: './new-price.component.html',
  styleUrls: ['./new-price.component.scss']
})
export class NewPriceComponent implements OnInit, OnDestroy {
  priceInfo?: IPriceInfo;
  text?: string;

  taxiPriceSubscription: Subscription;
  languageChangeSubscription?: Subscription;

  constructor(private priceService: PriceService, private translate: TranslateService) {
    this.taxiPriceSubscription = priceService.taxiPrice.subscribe(p => {
      this.priceInfo = p;
      this.updateText();
    });

    this.languageChangeSubscription = this.translate.onDefaultLangChange.subscribe(() => this.updateText());
  }

  ngOnInit(): void {
    this.updateText();
  }

  ngOnDestroy(): void {
    this.taxiPriceSubscription.unsubscribe();
    this.languageChangeSubscription?.unsubscribe();
  }

  updateText() {
    if (this.priceInfo?.isFixedDestination) {
      this.text = this.priceInfo.customerPrice?.toString() + " " + this.priceInfo.currency?.toString();
    }
    else if (this.priceInfo?.showPrice) {
      this.text = this.priceInfo.taxiPrice?.toString() + " " + this.priceInfo.currency?.toString();
    }
    else {
      this.text = "";
    }
  }
}