import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription, timer } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { DisplayOptions } from 'src/app/models/enums';
import { GetTaxiService } from 'src/app/services/get-taxi.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { getCurrentMonthName } from 'src/tools/get-current-month-name';

@Component({
    selector: 'app-bonus-dashboard',
    templateUrl: './bonus-dashboard.component.html',
    styleUrls: ['./bonus-dashboard.component.scss']
})
export class BonusDashboardComponent implements OnInit, OnDestroy {
    completedTaxiCountTotal: number = 0;
    completedTaxiCountMonth: number = 0;
    monthlyRidesGoal: number = 0;
    displayCoinCounter: boolean = false;
    displayBasicCounter: boolean = false;
    displayProgressBar: boolean = false;
    currency: string = "";
    earnedCoinsPerRide: number = 0;
    alreadyGotPaidAmount: number = 0;
    rating: number | undefined;
    averageTime: number | undefined;

    hotelId: string = "";
    accessToken: any;
    prepaidTourCompletedShow: boolean = false;
    popupTimer: number = 10000;
    isWhiteTextColor: boolean = false;
    showChristmasImage: boolean;
    private intervalId: any;

    completedTimerSubscription?: Subscription;
    ratingTimerSubscription?: Subscription;
    averageTimerSubscription?: Subscription;
    subscr$ = new Subject();

    constructor(private router: Router, private translate: TranslateService, public service: GetTaxiService, private parameterService: ParameterService) {
        const today = new Date();
        const currentMonth = today.getMonth(); // Month is 0-indexed (0 is January)
        const currentDay = today.getDate();
        this.showChristmasImage = (currentMonth === 11 && currentDay < 27);

        this.service.hotelInfo$.pipe(takeUntil(this.subscr$)).subscribe((data) => {
            this.displayCoinCounter = (data.displayOptions & DisplayOptions.DisplayCoinCounter) === DisplayOptions.DisplayCoinCounter;
            this.displayBasicCounter = (data.displayOptions & DisplayOptions.DisplayBasicCounter) === DisplayOptions.DisplayBasicCounter;
            this.displayProgressBar = (data.displayOptions & DisplayOptions.DisplayProgressBar) === DisplayOptions.DisplayProgressBar;
            this.monthlyRidesGoal = data.monthlyRidesGoal;
            this.currency = data.currency;
            this.earnedCoinsPerRide = data.earnedCoinsPerRide;
            this.alreadyGotPaidAmount = data.alreadyGotPaidAmount;
        });

        this.parameterService.parameters.subscribe(async (params) => {
            if (params === undefined)
                return;

            this.hotelId = params.hotelId;
            this.accessToken = params.accessToken;
        });

        this.router.events.subscribe(() => {
            const currentRoute = this.router.url;
            // Check if the current route contains the specific parent route
            this.isWhiteTextColor = currentRoute.includes('restaurant') || currentRoute.includes('resturant') || currentRoute.includes('fast-order');
        });
    }

    ngOnInit(): void {
        this.completedTimerSubscription = timer(0, 120000).pipe(
            map(() => {
                this.service.getCompletedTaxiCount({ hotelId: this.hotelId, accessToken: this.accessToken })
                    .subscribe(async (data) => {
                        this.completedTaxiCountTotal = data.completedRidesTotal;
                        this.completedTaxiCountMonth = data.completedRidesMonthly;
                    });
            })
        ).subscribe();

        this.ratingTimerSubscription = timer(0, 120000).pipe(
            map(() => {
                this.service.getRatingForHotel(this.hotelId)
                    .subscribe(data => {
                        this.rating = data;
                    })
            })
        ).subscribe();

        this.averageTimerSubscription = timer(0, 120000).pipe(
            map(() => {
                this.service.getAvergeTime(this.hotelId)
                    .subscribe(async data => {
                        this.averageTime = data;
                    })
            })
        ).subscribe();

        this.checkDate();
        this.intervalId = setInterval(() => {
            this.checkDate();
        }, 60 * 60 * 1000); // Check the date every hour (60 min, 60 seconds, 1000 milliseconds)
    }

    ngOnDestroy(): void {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }

        this.completedTimerSubscription?.unsubscribe();
        this.ratingTimerSubscription?.unsubscribe();
        this.averageTimerSubscription?.unsubscribe();
        this.subscr$.next();
        this.subscr$.complete();
    }

    getCoinCount(): number {
        return (this.completedTaxiCountTotal % 500) / 5;
    }

    getCoinProgressBarCount(): number {
        return Math.floor((this.completedTaxiCountTotal % 500) / 5);
    }

    getGiftCount(): number {
        return Math.floor(this.completedTaxiCountTotal / 500);
    }

    getEarnedCoins(): number {
        var result = this.completedTaxiCountTotal * this.earnedCoinsPerRide;
        result -= this.alreadyGotPaidAmount;
        return result;
    }

    getEarnedCoinsMonthly(): number {
        return this.completedTaxiCountMonth * this.earnedCoinsPerRide;
    }

    getProgressPercentage(): number {
        return (this.getEarnedCoinsMonthly() / this.monthlyRidesGoal) * 100;
    }

    getTreeProgressBarCount(): number {
        return Math.floor((this.completedTaxiCountTotal % 175) / 1.75);
    }

    getTreeCount(): number {
        return Math.floor(this.completedTaxiCountTotal / 175);
    }

    getMonthlyBonusGoalTitle(): string {
        return this.translate.instant('monthly_bonus_goal_title').replace('{month}', getCurrentMonthName());
    }

    private checkDate(): void {
        const today = new Date();
        const currentMonth = today.getMonth(); // Month is 0-indexed (0 is January)
        const currentDay = today.getDate();

        // Show image if it's December 25th
        this.showChristmasImage = !this.isWhiteTextColor && (currentMonth === 11 && currentDay < 27);
    }
}
