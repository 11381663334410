<div class="container">
    <app-station-header [logoSrc]="hotelInfo?.logoSrc" [language]="hotelInfo?.language"></app-station-header>
    
    <div *ngIf="hasMultipleTaxiCompanies()" class="taxi-choose">
        <mat-radio-group color="primary" class="button-section" [(ngModel)]="selectedTaxiCompany" (ngModelChange)="selectedTaxiCompany = $event">
            <mat-radio-button *ngFor="let taxiName of taxiNames; let i = index" 
            [value]="taxiName" 
            [ngClass]="{ 'first': i === 0, 'last': i === taxiNames.length - 1 }"
            (change)="onTaxiBoxClick(taxiName)"
            class="custom-radio-box">
                <div class="taxi-item">
                    <!-- Image element with dynamic src -->
                    <img *ngIf="isLogoAvailable[taxiName]" 
                    [src]="'assets/images/taxilogos/' + taxiName + '.png'" 
                    alt="{{ taxiName }} logo" 
                    class="taxi-logo" 
                    (error)="isLogoAvailable[taxiName] = false" />
                    <h4 class="taxi-name">{{ taxiName }}</h4>
                </div>
            </mat-radio-button>
        </mat-radio-group>
    </div>

    <form [formGroup]="taxiOrderForm" class="main">
        <div class="mobile-container">
            <div class="from">
                <div class="title">{{ "from" | translate }}</div>
                <app-destination-selector class="control"
                                            [showErrors]="this.taxiOrderForm.controls['fromLocation'].value?.name === undefined && isSubmit"
                                            [hotelInfo]="hotelInfo"
                                            [fromHotel]="true"
                                            [rounding]="true"
                                            formControlName="fromLocation"
                                            class="destination"></app-destination-selector>
            </div>

            <div class="to">
                <div class="title">{{ "to" | translate }}</div>
                <app-destination-selector class="control destination"
                                            formControlName="destination"
                                            [showErrors]="this.taxiOrderForm.controls['destination'].value?.name === undefined && isSubmit"
                                            [hotelInfo]="hotelInfo"
                                            [fromHotel]="false"
                                            [rounding]="true"
                                            [disabled]="noDestinationSelected"></app-destination-selector>
            </div>

            <div *ngIf="noDestinationAllowed && payInTaxiAllowed">
                <mat-checkbox color="primary" (change)="onNoDestinationChange($event)" class="checkBox">
                    {{ "noDestination" | translate }}
                </mat-checkbox>
            </div>
        </div>
        
        <div class="error-message"
                *ngIf="taxiOrderForm.controls['destination'].invalid">{{ "selfServiceStation_destinationError" |
            translate }}</div>

        <div class="title m-t-8">{{ "selfServiceStation_dateTimeTitle" | translate }}</div>
        <div class="pick-up">
            <app-date-time-picker [maxDate]='maxDate'
                                    formControlName="pickupDateTime"
                                    class="date-time-picker"
                                    [autoIncrementTime]="true"
                                    [rounding]="true"
                                    (click)="usingDatePicker()"
                                    (change)="dateChange()"></app-date-time-picker>
            <mat-checkbox color="primary"
                            (change)="onAsap($event)"
                            formControlName="isAsap"
                            class="asap">{{ "selfServiceStation_asapText" | translate }}</mat-checkbox>
        </div>

        <div class="title">{{ "selfServiceStation_fullNameTitle" | translate }}</div>
        <ion-input class="border control input-shadow full-name"
                    formControlName="fullName"
                    placeholder="{{'selfServiceStation_fullNamePlaceHolder' | translate}}"></ion-input>
        <div class="error-message"
                *ngIf="taxiOrderForm.controls['fullName'].invalid && isSubmit">{{ "selfServiceStation_fullNameError" |
            translate }}
        </div>

        <div class="title">{{ "selfServiceStation_phoneNumberTitle" | translate }}</div>
        <app-phone-number-input class="phone-number"
                                (onPressNumber)="phoneSelect($event)"
                                [hotelId]="hotelId"
                                [rounding]="true"></app-phone-number-input>
        <div class="error-message"
            *ngIf="taxiOrderForm.controls['phoneNumber'].invalid && isSubmit">{{
            "selfServiceStation_phoneNumberError" | translate }}
        </div>

        <div class="title m-t-8 margin-top">{{ "paymentOptions" | translate }}</div>

        <div *ngIf="payInTaxiAllowed" class="payInTaxiContainer">
            <mat-checkbox color="primary"
                (change)="onPayInTaxiSelected($event)"
                class="asap checkBox_margin"
                [checked]="payInTaxi"
                [disabled]="noDestinationSelected">{{ "payInTaxi" | translate }}</mat-checkbox>
        </div>  

        <div class="order-submit-wrapper">
            <button mat-flat-button
                    class="order-submit"
                    [disabled]="!taxiOrderForm.valid"
                    (click)="onClick()"
                    color="primary">
                <div>
                    <div class="btn_text">{{ getOrderButtonText() }}</div>
                    <div class="spinner" *ngIf="btnLoader"></div>
                </div>
                <div *ngIf="showPaymentOptions()">
                    <img src="/assets/images/PaymentOptions.png" class="paymentOptionImage"/>
                </div>
                <div *ngIf="showPrice()" class="flextext_btn top">
                    <div *ngIf="payInTaxi">
                        <ion-input readonly="true"
                            [value]=localPrice></ion-input>
                        <div>/</div>
                        <ion-input readonly="true"
                            [value]=euroPrice style="text-align: left;"></ion-input>
                    </div>
                </div>
            </button>
        </div>

        <div class="cancelContainer">
            <button mat-flat-button
                class="cancelButton"
                (click)="resetInterface()">
                {{ 'selfServiceStation_deleteOrderButton' | translate }}
            </button>
        </div>

        <div class="bottom-text">{{ "selfServiceStation_bottomText" | translate }}</div>
    </form>
</div>