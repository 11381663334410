import { Component, EventEmitter, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { GetTaxiService } from '../../services/get-taxi.service';
import { HotelResponseMapperService } from '../../mappers/hotel-response-mapper.service';
import { IHotelInfo } from '../../models/hotel-info.interface';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { TimeoutPopupComponent } from '../../shared/components/timeout-popup/timeout-popup.component';
import { PaidPopupComponent } from '../../shared/components/paid-popup/paid-popup.component';
import { PaymentApiService } from '../../services/api/payment-api.service';
import { SaveTaxiService } from '../../services/save-taxi.service';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ErrorOrderPopupComponent } from '../../shared/components/error-order-popup/error-order-popup.component';
import { CollectPaymentPopupComponent } from '../../shared/components/collect-payment-popup/collect-payment-popup.component';
import { ITaxiPrice } from '../../models/taxi-price.interface';
import { AddressValidator } from '../../../validators/address.validator';
import { InitPopupComponent } from '../../shared/components/init-popup/init-popup.component';
import { OrderType } from 'src/app/enums/order-type.enum';
import { TaxiConfigurationService } from 'src/app/services/taxi-configuration.service';
import { HotelService, NoDestinationSettings, PaymentOption } from 'src/app/services/hotel.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { IPaymentIntentResponse } from 'src/app/models/payment-intent-response.interface';
import { CollectPaymentMethodResponse } from 'src/app/models/payment/terminal.interface';
import { PriceService } from 'src/app/services/price.service';
import { IDestination } from 'src/app/shared/models/destination.interface';
import { ISaveOtherDestinationTerminalRequest } from 'src/app/models/ride/save-otherdestination-terminal.interface';
import { SpecialHotelInformationPopup } from 'src/app/shared/components/special-hotel-info-popup/special-hotel-info-popup.component';
import { ISaveOtherDestinationRequest } from 'src/app/models/ride/save-otherdestination.interface';
import { IDirectTaxiRequest } from 'src/app/models/ride/directtaxi.interface';
import { OrderedPopupComponent } from 'src/app/shared/components/ordered-popup/ordered-popup.component';
import { hasPaymentOptionFlag } from 'src/app/utils/flag';
import { Price } from 'src/app/services/models/price.model';
import { GetHotelSystem } from 'src/app/services/get-hotel.service';
import { HotelSettings } from 'src/app/models/enums';
import { IHotelSpecialLocations } from 'src/app/models/hotel-special-locations.interface';

@Component({
    selector: 'app-self-service-station',
    templateUrl: './self-service-station.component.html',
    styleUrls: ['./self-service-station.component.scss']
})

export class SelfServiceStationComponent implements OnInit {
    private accessToken?: string;
    private terminalReaderId?: string;
    loadingTaxiPrice: EventEmitter<boolean>;
    taxiPrice: ITaxiPrice = { taxiPrice: 0, serviceFee: 0 };
    isSubmit = false;
    resetTimer: any;
    popUpTimer: any;
    isAsap = false;
    isOpenPopup = false;
    hotelInfo?: IHotelInfo;
    loading!: HTMLIonLoadingElement;
    isFirstPageClick: boolean = true;
    hotelId: string = '';
    days = 25;
    maxDate = new Date(Date.now() + this.days * 24 * 60 * 60 * 1000);
    selectedDate = new Date();
    noDestinationSelected: boolean = false;
    payInTaxiSelected: boolean = false;
    options: any;
    onlyPayInTaxi: boolean = false;
    airports?: { id: string, name: string, address: string }[] = [];
    checkedAirportId: string = "";
    whatToShow: number = 0;
    specialLocations: IHotelSpecialLocations[] | undefined;

    submit() {
        if (this.pickupTime?.value) {
            this.getHotelSystem.getSpecialHotelInformation(this.pickupTime!.value.toISOString())
                .subscribe(
                    data => {
                        if (data && data.length > 0) {
                            const specialInformation = this.dialog.open(SpecialHotelInformationPopup, { data })
                            specialInformation.afterClosed().subscribe(_ => this.checkout())
                        }
                        else
                            this.checkout();
                    },
                    error => {
                        this.checkout()
                    }
                );
        }
        else
            this.checkout();
    }

    async checkout(): Promise<void> {
        if (this.noDestinationSelected) {
            this.saveDirectTaxi();
            return;
        }

        if (!this.priceService.price || !this.priceService.rideType.value)
            throw new Error('PriceInfo or customerPrice or rideType not defined');

        if (this.taxiOrderForm.value.paymentOption == PaymentOption.PayInTaxi) {
            this.saveOtherDestination();
            return;
        }

        await this.presentLoading('paymentInProcessLoader');

        await this.paymentApiService.createIntent({ hotelId: this.hotelId, guestName: this.taxiOrderForm.value.fullName, terminalReaderId: this.terminalReaderId!, jsonWebToken: this.priceService.jsonWebToken! }).toPromise().then(async (data: IPaymentIntentResponse) => {
            console.log('createIntent');

            const collectPaymentPopupRef = this.dialog.open(CollectPaymentPopupComponent, { disableClose: true });
            await this.hideLoading();

            await this.paymentApiService.collectPayment(data).then(async (collectPaymentResult: CollectPaymentMethodResponse) => {
                collectPaymentPopupRef.close();

                this.saveOtherDestinationTerminal(collectPaymentResult.paymentIntent.id);

            }).catch(() => { this.loading.dismiss(); });
        });
    }

    constructor(private translate: TranslateService, public loadingController: LoadingController, private saveTaxiService: SaveTaxiService, private parameterService: ParameterService, private getTaxiService: GetTaxiService, public dialog: MatDialog, private paymentApiService: PaymentApiService, private taxiConfigurationService: TaxiConfigurationService, public priceService: PriceService, public hotelService: HotelService, private getHotelSystem: GetHotelSystem) {
        let lang = localStorage.getItem('lang');
        translate.setDefaultLang(lang ? lang : 'en');
        this.loadingTaxiPrice = new EventEmitter();
    }

    taxiOrderForm: FormGroup = new FormGroup({
        fetchedPrice: new FormControl(true, [Validators.requiredTrue]),
        destination: new FormControl({ value: '', disabled: false }, [AddressValidator.detailedAddressValidator]),
        pickupDateTime: new FormControl(new Date(), [Validators.required]),
        fullName: new FormControl(''),
        isAsap: new FormControl(false),
        phoneNumber: new FormControl('', [Validators.required, Validators.min(4)]),
        email: new FormControl('', [Validators.email]),
        paymentOption: new FormControl(PaymentOption.PayByTerminal, [Validators.required])
    });

    ngOnInit(): void {
        this.parameterService.parameters.subscribe(async (params) => {
            if (params === undefined)
                return;

            this.hotelId = params.hotelId;
            this.accessToken = params.accessToken;
            this.terminalReaderId = params.terminalReaderId;

            const initPopupComponent = this.dialog.open(InitPopupComponent, {
                height: '99%',
                data: {
                    hotelId: this.hotelId,
                    accessToken: this.accessToken,
                    logoSrc: this.hotelInfo?.logoSrc,
                    from: <IDestination>{ name: "loading...", address: "loading..." },
                    airport: undefined,
                    locations: undefined
                }
            });
            initPopupComponent.afterClosed().subscribe((value: number) => {
                this.whatToShow = value;
                if (value == 1)
                    this.taxiOrderForm.controls.paymentOption.setValue(PaymentOption.PayInTaxi);
                else if (value == 2) {
                    this.taxiOrderForm.controls.destination.setValue(this.airports![0]);
                    this.checkedAirportId = this.airports![0].id;
                }
                else if (value == 3)
                    this.taxiOrderForm.controls.destination.setValue(this.hotelInfo?.hotelSpecialLocations?.[0]);
                else if (value == 4) {
                    this.taxiOrderForm.controls.destination.setValue(this.hotelInfo?.hotelSpecialLocations?.[1]);
                    this.whatToShow = 3;
                }
            });

            this.getTaxiService.getHotelInfo(params.hotelId)
                .pipe(catchError(err => {
                    setInterval(() => {
                        this.resetInterface();
                    }, environment.selfServiceStation_ResetInterfaceIfLoadingFailureTimeoutMs);
                    this.hotelInfo!.address = 'loading failure';
                    this.hotelInfo!.name = 'loading failure';
                    return throwError(err);
                }))
                .subscribe(data => {
                    this.hotelInfo! = HotelResponseMapperService.mapHotelInfoToIHotelInfo(data)

                    if (this.hotelInfo!.hotelSpecialLocations) {
                        const filteredArray = this.hotelInfo?.hotelSpecialLocations.filter(obj => !obj.name!.includes("*"));
                        this.hotelInfo!.hotelSpecialLocations = filteredArray;

                        if (filteredArray.length > 0)
                            this.specialLocations = filteredArray;
                    }

                    if (this.hotelInfo?.language == 'se')
                        this.translate.setDefaultLang('en');
                    else if ((this.hotelInfo.hotelSettings & HotelSettings.SelfServiceEnglish) == HotelSettings.SelfServiceEnglish)
                        this.translate.setDefaultLang('en');
                    else
                        this.translate.setDefaultLang(this.hotelInfo?.language);
                    localStorage.setItem("lang", this.hotelInfo?.language);

                    const from: IDestination = { name: this.hotelInfo?.name, address: this.hotelInfo?.address };
                    this.priceService.setFrom(from);

                    if (!this.terminalReaderId)
                        this.terminalReaderId = this.hotelInfo?.terminalReaderId;

                    // Access the component instance
                    const componentInstance = initPopupComponent!.componentInstance;

                    // Update properties directly
                    componentInstance.updateComponentData(
                        from,
                        this.airports![0],
                        this.hotelInfo?.logoSrc,
                        this.specialLocations)
                });

            this.getTaxiService.getAirportsForHotel(this.hotelId).subscribe(airports => {
                this.airports = airports;
            });
        });

        this.taxiOrderForm.get('destination')?.valueChanges.subscribe(async (value: IDestination) => {
            if (!value?.address) return;

            //If only we could uncheck the bastard airport radio button
            //if (!this.airports!.some(a => a.address === value.address)) {}

            this.priceService.setTo(value);
        });

        this.priceService.loadingTaxiPrice.subscribe(value => {
            this.taxiOrderForm.controls.fetchedPrice.setValue(!value);
        });

        this.taxiOrderForm.get('paymentOption')?.valueChanges.subscribe(async (value: PaymentOption) => {
            this.setPayButtonText(value);
            this.priceService.setPaymentOption(value);
        });

        this.hotelService.paymentOptions.subscribe(options => {
            this.options = options;

            /**
             * The following block is checking if the hotel has payInTaxi and NOT payByTerminal
             * If so, then the payment option is set to payInTaxi and the payInTaxi checkbox is checked and disabled
             * Made for HR-278 (16.01.24)
             */
            if (hasPaymentOptionFlag(options, PaymentOption.PayInTaxi) &&
                !hasPaymentOptionFlag(options, PaymentOption.PayByTerminal)) {
                this.taxiOrderForm.controls.paymentOption.setValue(PaymentOption.PayInTaxi);

                this.onlyPayInTaxi = true;
            }
            else {
                this.setPayButtonText(this.taxiOrderForm.value.paymentOption)
                this.onlyPayInTaxi = false;
            }
        });
    }

    setPayButtonText(value: PaymentOption) {
        if (value === PaymentOption.PayByTerminal)
            this.payInTaxiSelected = false;
        else if (value === PaymentOption.PayInTaxi)
            this.payInTaxiSelected = true;
        else if (value === PaymentOption.ApplyToBill)
            this.payInTaxiSelected = false;
    }

    phoneSelect(event: any): void {
        this.taxiOrderForm.controls.phoneNumber.setValue(event?.tel);
    }

    onClick(): void {
        this.isSubmit = true;
        if (!this.taxiOrderForm.valid)
            return;

        this.submit();
    }

    showAirports() {
        if (!this.airports)
            return false;
        else if (this.noDestinationSelected)
            return false
        return true
    }

    airportChange(event: MatCheckbox) {
        if (event.checked) {
            var found = this.airports!.find(e => e.id == event.value);
            this.taxiOrderForm.controls.destination.setValue(found);
            this.checkedAirportId = found!.id;
        }
        else {
            this.checkedAirportId = "";
            this.taxiOrderForm.controls.destination.setValue({ adress: '', name: '' });
        }
    }

    get payInTaxi() {
        if (this.taxiOrderForm.value.paymentOption == PaymentOption.PayInTaxi)
            return true;
        else return false;
    }

    get noDestinationAllowed() {
        if (this.hotelInfo == undefined) return false;
        if ((this.hotelInfo.noDestinationSettings & NoDestinationSettings.NotAllowed) == NoDestinationSettings.NotAllowed && this.hotelInfo?.selfServicePayInTaxiAllowed)
            return false;
        else
            return true;
    }

    get payInTaxiAllowed() {
        if ((this.options & PaymentOption.PayInTaxi) === PaymentOption.PayInTaxi && this.hotelInfo?.selfServicePayInTaxiAllowed)
            return true;
        else
            return false;
    }

    get pickupTime() {
        return this.taxiOrderForm.get('pickupTime');
    }

    get paymentOption() {
        return this.taxiOrderForm.get('paymentOption');
    }

    get priceText() {
        if (this.taxiConfigurationService.taxiNameValue === "Dantaxi")
            return this.translate.instant("selfServiceStation_expectedPriceTitle");
        else
            return this.translate.instant("selfServiceStation_priceTitle");
    }

    get destination() {
        return this.taxiOrderForm.get('destination');
    }

    async saveOtherDestination() {
        await this.presentLoading('orderingTaxiLoader');

        const body: ISaveOtherDestinationRequest = {
            hotelId: this.hotelId,
            accessToken: this.accessToken!,
            location: this.taxiOrderForm.value.destination,
            guestName: this.taxiOrderForm.value.fullName,
            phoneNumber: this.taxiOrderForm.value.phoneNumber,
            pickupTime: this.taxiOrderForm.value.pickupDateTime.toISOString(),
            orderType: OrderType.Terminal,
            paymentOption: this.taxiOrderForm.value.paymentOption,
            rideType: this.priceService.rideType.value,
            jsonWebToken: this.priceService.jsonWebToken,
            largeTaxi: false,
            numberOfGuests: 1,
            numberOfCars: 1,
            stationCar: false,
            attributesToTaxi: 0,
            comment: null
        }

        this.saveTaxiService.saveOtherDestinations(body).subscribe(async () => {
            await this.hideLoading();
            const dialogRef = this.dialog.open(OrderedPopupComponent);
            dialogRef.afterClosed().subscribe(_ => {
                this.resetInterface();
            });
        }, async () => {
            await this.hideLoading();
            this.openErrorPopUp(this.taxiConfigurationService.taxiNameValue, this.taxiConfigurationService.taxiHotelNumberValue);
        });

    }

    async saveOtherDestinationTerminal(paymentIntentId: any) {
        await this.presentLoading('orderingTaxiLoader');

        const body: ISaveOtherDestinationTerminalRequest = {
            hotelId: this.hotelInfo!.id,
            accesstoken: this.accessToken,
            location: this.taxiOrderForm.value.destination,
            guestName: this.taxiOrderForm.value.fullName,
            phoneNumber: this.taxiOrderForm.value.phoneNumber,
            pickupTime: this.taxiOrderForm.value.pickupDateTime.toISOString(),
            paymentIntentId: paymentIntentId,
            orderType: OrderType.Terminal,
            paymentOption: PaymentOption.PayByTerminal,
            email: this.taxiOrderForm.value.email,
            isAsap: this.taxiOrderForm.value.isAsap,
            rideType: this.priceService.rideType.value!,
            jsonWebToken: this.priceService.jsonWebToken!,
            largeTaxi: false,
            stationCar: false,
            numberOfGuests: 1,
            numberOfCars: 1,
            attributesToTaxi: 0,
        };

        this.saveTaxiService.saveOtherDestinationTerminal(body).subscribe(async () => {
            await this.hideLoading();
            const dialogRef = this.dialog.open(PaidPopupComponent);
            dialogRef.afterClosed().subscribe(_ => {
                this.resetInterface();
            });
        }, async () => {
            await this.hideLoading();
            this.openErrorPopUp(this.taxiConfigurationService.taxiNameValue, this.taxiConfigurationService.taxiHotelNumberValue);
        });
    }

    async saveDirectTaxi() {
        await this.presentLoading('orderingTaxiLoader');

        const body: IDirectTaxiRequest = {
            hotelId: this.hotelId,
            accessToken: this.accessToken!,
            guestName: this.taxiOrderForm.value.fullName,
            phoneNumber: this.taxiOrderForm.value.phoneNumber,
            pickupTime: this.taxiOrderForm.value.pickupDateTime.toISOString(),
            numberOfCars: 1,
            stationCar: false,
            attributesToTaxi: 0,
            largeTaxi: false,
            paymentOption: PaymentOption.PayInTaxi,
            orderType: OrderType.Terminal
        }

        this.saveTaxiService.directTaxiRide(body).subscribe(async () => {
            await this.hideLoading();
            const dialogRef = this.dialog.open(OrderedPopupComponent);
            dialogRef.afterClosed().subscribe(_ => {
                this.resetInterface();
            });
        }, async () => {
            await this.hideLoading();
            this.openErrorPopUp(this.taxiConfigurationService.taxiNameValue, this.taxiConfigurationService.taxiHotelNumberValue);
        })
    }

    @HostListener('document:click', ['$event.target'])
    public onPageClick(): void {
        if (this.isFirstPageClick) {
            this.initTerminal();

            this.isFirstPageClick = false;
        }

        clearInterval(this.resetTimer);
        clearInterval(this.popUpTimer);

        this.resetTimer = setInterval(() => {
            this.resetInterface();
        }, environment.selfServiceStation_ResetInterfaceTimeoutMs);

        this.popUpTimer = setInterval(() => {
            if (!this.isOpenPopup) {
                this.isOpenPopup = true;
                this.openPopUp();
            }
        }, environment.selfServiceStation_ShowResetPopUpTimeoutMs);
    }

    private initTerminal(): void {
        if (this.hotelId && this.hotelInfo?.terminalLocationId && this.terminalReaderId)
            this.paymentApiService.setupTerminal(this.hotelId, this.hotelInfo?.terminalLocationId, this.terminalReaderId);
    }

    public openPopUp(): void {
        const dialogRef = this.dialog.open(TimeoutPopupComponent);
        dialogRef.afterClosed().subscribe(result => {
            this.isOpenPopup = false;
            if (result)
                this.resetInterface();
        });
    }

    onAsap($event: MatCheckboxChange): void {
        if ($event.checked) {
            this.isAsap = true;
            this.taxiOrderForm.controls.pickupDateTime.setValue(new Date());
            this.dateChange();
        } else {
            this.isAsap = false;
        }
    }

    onNoDestination($event: MatCheckboxChange): void {
        if ($event.checked) {
            this.taxiOrderForm.controls.paymentOption.setValue(PaymentOption.PayInTaxi);
            this.taxiOrderForm.controls.destination.disable();
            this.noDestinationSelected = true;
        }
        else if (this.payInTaxiSelected) {
            this.taxiOrderForm.controls.destination.enable();
            this.noDestinationSelected = false;
        }
        else {
            this.taxiOrderForm.controls.paymentOption.setValue(PaymentOption.PayByTerminal);
            this.taxiOrderForm.controls.destination.enable();
            this.noDestinationSelected = false;
        }
    }

    onPayInTaxiSelected($event: MatCheckboxChange): void {
        if ($event.checked) {
            this.taxiOrderForm.controls.paymentOption.setValue(PaymentOption.PayInTaxi);
            this.payInTaxiSelected = true;
        }
        else {
            this.taxiOrderForm.controls.paymentOption.setValue(PaymentOption.PayByTerminal)
            this.payInTaxiSelected = false;
        }
    }

    getOrderButtonText(): string {
        var value = this.paymentOption?.value;
        if (value === PaymentOption.ApplyToBill)
            return this.translate.instant("selfServiceStation_orderButtonText")

        else if (value === PaymentOption.PayInTaxi)
            return this.translate.instant("selfServiceStation_orderButtonText")

        else
            return this.translate.instant("selfServiceStation_payButtonText")
    }

    orderHereAndNow() {
        this.isSubmit = true;
        this.taxiOrderForm.controls.paymentOption.setValue(PaymentOption.PayInTaxi);
        this.saveDirectTaxi();
        return;
    }

    resetInterface(): void {
        this.paymentApiService.cancelCollectPaymentMethod();
        window.location.reload();
    }

    round(num: number): number {
        return Math.round(num);
    }

    public openErrorPopUp(taxiName: string, taxiHotelNumber: string): void {
        const dialogRef = this.dialog.open(ErrorOrderPopupComponent, {
            data: {
                taxiName,
                taxiHotelNumber
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            this.isOpenPopup = false;
            if (result)
                this.resetInterface();
        });
    }

    async presentLoading(message: string): Promise<void> {
        this.loading = await this.loadingController.create({
            cssClass: 'my-custom-class',
            message: this.translate.instant(message),
            duration: 20000
        });
        await this.loading.present();
    }

    async hideLoading(): Promise<void> {
        await this.loading.dismiss();
    }

    scrollToBottom() {
        setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 500);
    }

    usingDatePicker() {
        this.taxiOrderForm.get('isAsap')?.setValue(false);
    }

    dateChange(): void {
        this.selectedDate = this.taxiOrderForm.get('pickupDateTime')?.value;
        this.priceService.setSelectedDate(this.selectedDate);
    }

    getPrice(): Price | undefined {
        if (this.noDestinationSelected)
            return undefined;
        else if (this.priceService.isFixedDestination)
            return this.priceService.localPrice;
        else if (this.payInTaxi
            && hasPaymentOptionFlag(this.options, PaymentOption.PayInTaxi)
            && !hasPaymentOptionFlag(this.options, PaymentOption.PayByTerminal)
            && !hasPaymentOptionFlag(this.options, PaymentOption.ApplyToBill))
            return this.priceService.taxiLocalPrice
        else if (this.payInTaxi)
            return undefined;
        else
            return this.priceService.localPrice;
    }

    getEuroPrice(): Price | undefined {
        if (this.noDestinationSelected)
            return undefined;
        else if (this.priceService.isFixedDestination)
            return this.priceService.priceEuro;
        else if (this.payInTaxi
            && hasPaymentOptionFlag(this.options, PaymentOption.PayInTaxi)
            && !hasPaymentOptionFlag(this.options, PaymentOption.PayByTerminal)
            && !hasPaymentOptionFlag(this.options, PaymentOption.ApplyToBill))
            return this.priceService.taxiPriceEuro
        else if (this.payInTaxi)
            return undefined;
        else
            return this.priceService.priceEuro;
    }
}