import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GetTaxiService } from './get-taxi.service';
import { ParameterService } from './parameter.service';

export enum PaymentOption {
  None = 0,
  ApplyToBill = 1,
  Prepay = 2,
  PayInTaxi = 4,
  HotelPays = 8,
  PayByTerminal = 16
}

export enum HotelSystemIntegration {
  None = 0,
  HideNameAndPhone = 1,
  GetCustomer = 2,
  AddToBill = 4,
  RemoveFromBill = 8,
  QRAddToBill = 16,
  FromAirportAddToBill = 32,
  AutoApplyToBill = 64
}

export enum NoDestinationSettings {
  None = 0,
  NotAllowed = 1,
  WithNameAndPhone = 2,
  NoOrderNowButton = 4,
}

export enum FixedPricePaymentinTaxi {
  Allowed = 1,
  NotAllowed = 2,
  NotAllowedToFromAirport = 3,
}

export enum EstablishmentType {
  Hotel = 0,
  Restaurant = 1
}

@Injectable({
  providedIn: 'root'
})
export class HotelService {
  @Output() allRidesMustBePaidInTheTaxi = new EventEmitter<boolean>();
  allRidesMustBePaidInTheTaxiValue = false;

  @Output() sharingTaxies = new EventEmitter<boolean>();
  sharingTaxiesValue = false;

  @Output() allPaymentMethodShouldDisabled = new EventEmitter<boolean>();
  allPaymentMethodShouldDisabledValue = false;

  @Output() allRidesShouldBePaidByTheCustomer = new EventEmitter<boolean>();
  allRidesShouldBePaidByTheCustomerValue = false;

  @Output() receptionistInitialsRequired = new EventEmitter<boolean>();
  receptionistInitialsRequiredValue = false;

  @Output() paymentOptions = new EventEmitter<PaymentOption>();
  paymentOptionsValue = PaymentOption.ApplyToBill;

  @Output() fixedPricePaymentinTaxi = new EventEmitter<FixedPricePaymentinTaxi>();
  fixedPricePaymentinTaxiValue = FixedPricePaymentinTaxi.Allowed;

  @Output() defaultCommentToTaxiDriver = new EventEmitter<string>();
  defaultCommentToTaxiDriverValue = '';

  @Output() destinationSearchCountries = new BehaviorSubject<string[]>([]);

  @Output() taxiCompanyStatusMesssages = new BehaviorSubject<{ statusMessage: string; }[]>([]);

  @Output() isRushHour1Active = new BehaviorSubject<boolean>(false);
  @Output() isRushHour2Active = new BehaviorSubject<boolean>(false);
  @Output() isRushHour3Active = new BehaviorSubject<boolean>(false);

  @Output() currency = new BehaviorSubject<string>('');

  @Output() nameOrRoomNumberRequiredToTheAirport = new BehaviorSubject<boolean>(false);

  @Output() latitude = new BehaviorSubject<number>(0);
  @Output() longitude = new BehaviorSubject<number>(0);

  getHotelInfo: boolean = true;

  constructor(getTaxiService: GetTaxiService, private parameterService: ParameterService) {
    this.parameterService.parameters.subscribe(async (params) => {
      if (params === undefined) {
        this.getHotelInfo = false;
        return;
      }

      if (params.hotelId === undefined)
        this.getHotelInfo = false;
    });

    if (this.getHotelInfo) {
      getTaxiService.getHotelConfiguration()
        .subscribe(config => {
          this.allRidesMustBePaidInTheTaxi.emit(config.allRidesMustBePaidInTheTaxi);
          this.allRidesMustBePaidInTheTaxiValue = config.allRidesMustBePaidInTheTaxi;

          this.receptionistInitialsRequired.emit(config.receptionistInitialsRequired);
          this.receptionistInitialsRequiredValue = config.receptionistInitialsRequired;

          this.allRidesShouldBePaidByTheCustomer.emit(config.allRidesShouldBePaidByTheCustomer);
          this.allRidesShouldBePaidByTheCustomerValue = config.allRidesShouldBePaidByTheCustomer;

          this.paymentOptionsValue = config.paymentOptions;
          this.paymentOptions.emit(config.paymentOptions);

          this.fixedPricePaymentinTaxiValue = config.fixedPricePaymentinTaxi;
          this.fixedPricePaymentinTaxi.emit(config.fixedPricePaymentinTaxi);

          this.defaultCommentToTaxiDriverValue = config.defaultCommentToTaxiDriver;
          this.defaultCommentToTaxiDriver.emit(config.defaultCommentToTaxiDriver);

          this.destinationSearchCountries.next(config.destinationSearchCountries ? config.destinationSearchCountries.split(',') : []);

          this.taxiCompanyStatusMesssages.next(config.taxiCompanyStatusMessages);

          this.isRushHour1Active.next(config.isRushHour1Active);
          this.isRushHour2Active.next(config.isRushHour2Active);
          this.isRushHour3Active.next(config.isRushHour3Active);

          this.currency.next(config.currency);

          this.nameOrRoomNumberRequiredToTheAirport.next(config.nameOrRoomNumberRequiredToTheAirport);

          this.latitude.next(config.latitude);
          this.longitude.next(config.longitude);

          this.sharingTaxiesValue = config.sharingTaxies;
          this.sharingTaxies.next(config.sharingTaxies);
        });
    }
  }
}
