import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GetTaxiService } from 'src/app/services/get-taxi.service';
import { TaxiConfigurationService } from 'src/app/services/taxi-configuration.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { AlertController, LoadingController } from '@ionic/angular';
import { TaxiStatus, RideStatus, RideType } from 'src/app/models/enums';
import { IRide } from 'src/app/services/models/ride.model';

@Component({
  selector: 'app-new-upcoming-rides',
  templateUrl: './new-upcoming-rides.component.html',
  styleUrls: ['./new-upcoming-rides.component.scss']
})
export class NewUpcomingRidesComponent implements OnInit, OnDestroy {
  @Input() anonymousView: boolean = false;
  @Input() showCancel: boolean = false;
  @Output() rideSelected = new EventEmitter<string>(); // Output to send selected ride ID
  @Output() newRideDetected = new EventEmitter<boolean>();

  upcomingRidesSubscription: any;
  arrivalsOfTheDaySubscription: any;
  upcomingRidesTimer?: number;
  taxiHotelNumberSubscription: Subscription = new Subscription();
  onlyLatest: boolean = false;
  subscr$ = new Subject();
  loading!: HTMLIonLoadingElement;

  isInitialized = false;
  rides: IRide[] = [];
  previousRides: any[] = [];
  _date = new Date();
  selectedRide: string = '';

  inVainContactText: string = '';

  constructor(private getTaxiService: GetTaxiService, private translate: TranslateService, private taxiConfigurationService: TaxiConfigurationService, private parameterService: ParameterService, public loadingController: LoadingController, private alertController: AlertController) { }

  ngOnInit(): void {
    this.updateRides();

    const inVainContactTextUpdater = () => {
      this.inVainContactText = " " + this.translate.instant('upcoming-rides-in-vain-contact')
        .replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue)
        .replace('{taxiName}', this.taxiConfigurationService.taxiNameValue);
    };
    inVainContactTextUpdater();
    this.taxiConfigurationService.taxiHotelNumber.subscribe(taxiHotelNumber => {
      inVainContactTextUpdater();
    });
  }

  updateRides() {
    const arrivalsOfTheDaySubscription = this.getTaxiService.getArrivalsOfTheDay(this._date);
    const upcomingRidesSubscription = this.getTaxiService.getUpcomingRides();

    forkJoin([arrivalsOfTheDaySubscription, upcomingRidesSubscription])
      .pipe(takeUntil(this.subscr$))
      .subscribe((rides: Array<any>) => {
        this.isInitialized = true;

        // Filter and sort rides
        const filteredRides = rides[1]
          .filter((r: any) =>
            [RideType.Direct, RideType.OtherRide, RideType.ToAirport, RideType.FixedPrice].includes(r.rideType) &&
            [TaxiStatus.Ordered, TaxiStatus.OnItsWay, TaxiStatus.TaximeterStarted, TaxiStatus.InVain, TaxiStatus.NoShow].includes(r.taxiStatus)
          );

        const millisecondsPerMinute = 60000;
        const earliestPickupTime = new Date(Date.now() - millisecondsPerMinute * 150);

        const additionalRides = rides[0]
          .filter((r: any) =>
            !this.onlyLatest || new Date(r.pickupTime) >= earliestPickupTime
          )
          .filter((r: any) =>
            [TaxiStatus.Ordered, TaxiStatus.OnItsWay, TaxiStatus.TaximeterStarted, TaxiStatus.InVain, TaxiStatus.NoShow, TaxiStatus.Completed, TaxiStatus.NotOrderedYet]
              .includes(r.taxiStatus) ||
            (r.taxiStatus === TaxiStatus.Cancelled && r.rideStatus === RideStatus.CancelledBecauseTheFlightHasBeenDelayed)
          );

        const allRides = [...filteredRides, ...additionalRides];
        allRides.sort((a, b) => (a.pickupTime > b.pickupTime ? 1 : -1));

        // Check for new rides
        const newRides = allRides.filter(
          newRide => !this.previousRides.some(oldRide => oldRide.id === newRide.id)
        );

        if (newRides.length > 0)
          this.newRideDetected.emit(true); // Emit the new rides
        else if (allRides.length == 0)
          this.newRideDetected.emit(false); // Emit no rides

        // Update the previous rides
        this.previousRides = [...allRides];

        // Update rides and set timer
        this.rides = allRides;
        this.upcomingRidesTimer = window.setTimeout(() => this.updateRides(), 30000);
      },
        error => {
          this.upcomingRidesTimer = window.setTimeout(() => this.updateRides(), 60000);
        });
  }


  ngOnDestroy(): void {
    this.subscr$.next();
    this.subscr$.complete();
    clearTimeout(this.upcomingRidesTimer);

    this.taxiHotelNumberSubscription.unsubscribe();
  }

  selectRide(rideId: string): void {
    if (rideId == this.selectedRide)
      rideId = '';
    this.selectedRide = rideId;
    this.rideSelected.emit(rideId);
  }

  async cancelRide(ride: IRide) {
    this.parameterService.parameters.subscribe(async (params) => {
      if (params === undefined)
        return;

      let body = {
        id: ride.id
      }

      await this.presentLoading()

      this.getTaxiService.cancelRide(body).subscribe(async (data) => {
        await this.hideLoading()

        var textKey = this.translate.instant('backendStatusCodeTaxiCouldNotBeCancelled').replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue);
        if (data.taxiIsCancelled) {
          textKey = this.translate.instant('taxiHasBeenCancelled');
          ride.taxiStatus = TaxiStatus.Cancelled;
        }

        const alert = await this.alertController.create({
          header: this.translate.instant('taxiStatusHeader'),
          subHeader: textKey,
          cssClass: 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ],
          backdropDismiss: false
        });
        await alert.present();
      }, async (error) => {
        await this.hideLoading()
        if (error.status == 400) {
          const errorAlert = await this.alertController.create({
            header: this.translate.instant('error'),
            subHeader: this.translate.instant(`backendStatusCode${error.error.statusCode}`).replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue).replace('{taxiName}', this.taxiConfigurationService.taxiNameValue),
            cssClass: !error.error ? 'alertmessage' : !error.error.statusCode ? 'alertmessage' : error.error.statusCode == "LargeTaxiNotAvailable" ? 'alertmessage2' : 'alertmessage',
            buttons: [
              {
                text: 'Okay',
                handler: () => {
                }
              }
            ]
          });
          errorAlert.present();
        }
        else {
          const alert = await this.alertController.create({
            header: "Error Occurred",
            subHeader: this.translate.instant('500errorMsg'),
            cssClass: 'alertmessage',
            buttons: [
              {
                text: 'Okay',
                handler: () => {
                }
              }
            ],
            backdropDismiss: false
          });
          await alert.present();
        }
      })
    });
  }

  statusOnItsWay(ride: IRide) {
    return ride.taxiStatus === TaxiStatus.OnItsWay;
  }

  statusTaximeterStarted(ride: IRide) {
    return ride.taxiStatus === TaxiStatus.TaximeterStarted;
  }

  statusInVain(ride: IRide) {
    return ride.taxiStatus === TaxiStatus.InVain;
  }

  statusUnknown(ride: IRide) {
    return ride.taxiStatus === TaxiStatus.Unknown;
  }

  statusCancelled(ride: IRide) {
    return ride.taxiStatus === TaxiStatus.Cancelled;
  }

  statusNoShow(ride: IRide) {
    return ride.taxiStatus === TaxiStatus.NoShow;
  }

  statusCompleted(ride: IRide) {
    return ride.taxiStatus === TaxiStatus.Completed;
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: this.translate.instant('pleaseWait'),
    });
    await this.loading.present();
  }

  async hideLoading() {
    await this.loading.dismiss();
  }
}