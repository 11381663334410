<div class="action">
    <div class="error"
         *ngIf="missingInitials">{{ 'order-error-missing-initials' | translate }}</div>

    <button mat-flat-button
            color="primary"
            type="submit">

        <app-price textWhenPriceIsUnknown="orderTaxiButton"
                   textWhenPriceIsKnown="orderTaxiWithKnownPriceButton"
                   [hidden]="usingFixedPriceButPaidInTheTaxi()">
        </app-price>

        <span>{{ 'orderTaxiButton' | translate }}</span>

        <app-price-as-text [hidden]="!isPayByTerminal()"></app-price-as-text>

        <div [hidden]="!isApplyToBill()">{{ 'taxiPriceApplyToBill' | translate }}</div>

        <div [hidden]="!isHotelPayment()">{{ 'taxiPricePaidByHotel' | translate }}</div>

        <div [hidden]="!isPaidInTaxi()">{{ 'taxiPricePaidInTaxi' | translate }}</div>

        <div [hidden]="!isPrepaid()">{{ 'taxiPricePrepaid' | translate }}</div>
    </button>
</div>
