<div class="grid-container" *ngIf="isInitialized && rides.length === 0">
    {{ 'arrivals-of-the-day-none-planned' | translate }}
</div>

<div class="grid-container" *ngIf="rides.length > 0">
      <div class="grid-item" *ngFor="let ride of rides">
            <div>{{ ride.pickupTime + 'z' | date:'HH:mm' }}</div>
            <div *ngIf="ride.to">{{ 'to' | translate }} {{ ride.to }}</div>
            <div *ngIf="ride.roomNumber">{{ 'arrivals-of-the-day-room-number' | translate }} {{ ride.roomNumber }}</div>
            <div *ngIf="ride.guestName">{{ 'arrivals-of-the-day-guest-name' | translate }} {{ ride.guestName }}</div>
            <span *ngIf="statusOnItsWay(ride)"
                  class="status on-its-way">{{ 'arrivals-of-the-day-on-its-way' | translate }}</span>
            <span *ngIf="statusTaximeterStarted(ride)"
                  class="status taximeter-started">{{ 'arrivals-of-the-day-taximeter-started' | translate }}</span>
            <span *ngIf="statusInVain(ride)"
                  class="status in-vain">{{ 'arrivals-of-the-day-in-vain' | translate }}</span>
            <span *ngIf="statusCancelledBecauseOfADelayedFlight(ride)"
                  class="status cancelled">{{ 'arrivals-of-the-day-cancelled-because-of-a-delayed-flight' | translate }}</span>
            <span *ngIf="statusNoShow(ride)"
                  class="status in-vain">{{ 'upcoming-rides-no-show' | translate }}</span>
      </div>
</div>
