import { Injectable } from "@angular/core";
import { AbstractControl, FormGroup, ValidationErrors } from "@angular/forms";
@Injectable({
    providedIn: 'root'
})
export class ValidationTools {
    oneOfTwoFieldsRequired(depControl: AbstractControl) {
        return (primControl: AbstractControl): ValidationErrors | null => {
            var primControlValue = primControl.value;
            var secControlValue = depControl.value;
            if (primControlValue && primControlValue != "") {
                return null;
            }
            else if (secControlValue && secControlValue != "") {
                return null;
            } else {
                return { "phoneOrGuestNameRequired": true }
            }
        }
    }
}
export function locationValidator(control: AbstractControl): ValidationErrors | null {
    const location = control.value;
    if (location && (typeof location.name === 'string' && location.name.trim() === '' ||
        typeof location.address === 'string' && location.address.trim() === '')) {
        return { locationInvalid: true }; // Set the error
    }
    return null; // No error if valid
}
export function logInvalidControls(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(key => {
        const control = formGroup.get(key);
        if (control && control.invalid) {
            console.log(`Invalid control: ${key}`, {
                value: control.value,
                errors: control.errors
            });
        }
    });
}