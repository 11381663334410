import { IHotelInfo, IHotelInfoResponse } from '../models/hotel-info.interface';
import { IHotelSpecialLocations } from '../models/hotel-special-locations.interface';
import { IDestination } from '../shared/models/destination.interface';

export class HotelResponseMapperService {
    public static mapHotelInfoToIHotelInfo(data: IHotelInfoResponse): IHotelInfo {

        let lang = "en";

        switch (data.country) {
            case "Denmark":
                lang = "da"
                break;
            case "Norway":
                lang = "no"
                break;
            case "Germany":
                lang = "de"
                break;
            case "Sweden":
                lang = "se"
                break;
            case "Finland":
                lang = "fi"
                break;
        }

        var location: IHotelSpecialLocations[] | undefined = undefined;

        if (data.hotelSpecialLocations) {
            location = [];
            data.hotelSpecialLocations.forEach((element) => {
                location!.push({ name: element.name, address: element.address, showPrice: element.showPrice });
            });
        }

        return {
            id: data.id,
            name: data.name,
            address: data.address,
            hotelLogoSrc: data?.hotelLogoSrc,
            logoSrc: data?.logoSrc ?? 'assets/images/logo_white.svg',
            terminalLocationId: data.terminalLocationId,
            terminalReaderId: data.terminalReaderId,
            zipCode: data.zipCode,
            lockedMobileRoutes: data.lockedMobileRoutes,
            hasNewOrderingFlow: data.hasNewOrderingFlow,
            currency: data.currency,
            hasHotelSystemIntegration: data.hasHotelSystemIntegration,
            integrationOptions: data.integrationOptions,
            noDestinationSettings: data.noDestinationSettings,
            selfServicePayInTaxiAllowed: data.selfServicePayInTaxiAllowed,
            hotelSpecialLocations: location,
            country: data.country,
            establishmentType: data.establishmentType,
            hotelSettings: data.hotelSettings,
            displayOptions: data.displayOptions,
            longitude: data.longitude,
            latitude: data.latitude,
            language: lang,
            splitTest: data.splitTest
        };
    }
}
