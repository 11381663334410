import { Component, Input } from '@angular/core';
import { GdprPopupComponent } from '../gdpr-popup/gdpr-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { TaxiContactComponent } from 'src/app/components/taxi-contact/taxi-contact.component';

@Component({
  selector: 'app-new-header',
  templateUrl: './new-header.component.html',
  styleUrls: ['./new-header.component.scss']
})
export class NewHeaderComponent {
  @Input() logoSrc: string | undefined;
  @Input() gdpr: boolean = false;
  @Input() name: string | undefined;

  isHotel = false;
  isMobile = false;

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
    this.isHotel = this.checkIfPc(['resturant', 'restaurant', 'fast-order', 'assistant']);
    this.isMobile = this.checkIfMobile();
  }

  openGDPRPopUp(): void {
    this.dialog.open(GdprPopupComponent, {
      height: '90%',
      width: '90%'
    });
  }

  customerSupportPopUp(): void {
    this.dialog.open(TaxiContactComponent, {
      height: '50%',
      width: '50%'
    });
  }

  checkIfPc(bannedUrls: string[]): boolean {
    // Check if the URL contains the words it shouldnt have in bannedurls
    const currentUrl = window.location.href.toLowerCase();
    const urlContainsBannnedUrl = !bannedUrls.some(word => currentUrl.includes(word));

    // Define a regex pattern to check for PC user agents (commonly Windows or Linux)
    const pcPattern = /Windows NT|Macintosh|Linux/;
    const isPC = pcPattern.test(navigator.userAgent);

    return urlContainsBannnedUrl && isPC;
  }

  checkIfMobile(): boolean {
    // Define a regex pattern to check for Mobile user agents (commonly Windows or Linux)
    const pcPattern = /Windows NT|Macintosh|Linux/;
    const isPC = !pcPattern.test(navigator.userAgent);

    return isPC;
  }

  showName(): boolean {
    return false;
    //return this.name !== undefined
  }
}
