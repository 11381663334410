<ion-content *ngIf="splitTest != 0">
    <app-header *ngIf="splitTest == 1"></app-header>
    <app-new-header *ngIf="splitTest == 2"></app-new-header>
    <div class="center" *ngIf="monthlyRides.length == 0">
        <p>CO2 reporting coming soon.</p>
    </div>
    <div class="content" *ngIf="monthlyRides.length != 0">
        <div class="months" *ngFor="let months of monthlyRides">
            <div class="monthsBox">
                <div class="monthsYear">
                    {{months.year}}
                </div>
                <div class="monthsMonth">
                    {{getMonthName(months.month)}}
                </div>
            </div>
            <div class="downloadBox" [ngClass]="{'new': splitTest == 2}">
                <a class="co2PdfDownload" download="" href="{{getCO2PdfLink(months)}}">Download</a>
            </div>
            <hr [ngClass]="{'new-hr': splitTest == 2}">
        </div>
    </div>
</ion-content>
