import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { IDestination } from '../../models/destination.interface';
import { GetTaxiService } from 'src/app/services/get-taxi.service';
import { GetPriceRequest } from 'src/app/models/get-price-request.interface';
import { OrderType } from 'src/app/enums/order-type.enum';
import { PaymentOption } from 'src/app/services/hotel.service';
import { IPriceInfo } from 'src/app/services/models/price-info.model';
import { IHotelSpecialLocations } from 'src/app/models/hotel-special-locations.interface';

export interface InitPopupComponentArgs {
  hotelId: string;
  accessToken: string;
  from: IDestination;
  airport: IDestination | undefined;
  logoSrc?: string;
  locations?: IHotelSpecialLocations[] | undefined;
}

@Component({
  selector: 'app-init-popup',
  templateUrl: './init-popup.component.html',
  styleUrls: ['./init-popup.component.scss']
})

export class InitPopupComponent {
  from: IDestination | undefined = undefined;
  airport: IDestination | undefined = undefined;
  hotelId: string = '';
  private accessToken?: string;

  airportPrice: number | undefined = undefined;
  firstPrice: number | undefined = undefined;
  secondPrice: number | undefined = undefined;
  currency: string = '';

  private _logoSrc: string = 'assets/images/logo_white.svg';
  locations: IHotelSpecialLocations[] | undefined = undefined;

  set logoSrc(value: string | undefined) {
    if (value) this._logoSrc = value;
  }

  get logoSrc(): string | undefined {
    return this._logoSrc;
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: InitPopupComponentArgs, public dialogRef: MatDialogRef<InitPopupComponent>, private translate: TranslateService, private getTaxiService: GetTaxiService) {
    this.hotelId = data.hotelId;
    this.accessToken = data.accessToken;
    this.from = data.from;
    this.airport = data.airport;
    this.logoSrc = data.logoSrc;
    this.locations = data.locations;

    if (this.from && (this.airport || (this.locations && this.locations.length != 0))) {
      this.setPrices();
      this.scheduleTasks();
    }
  }

  setPrices(): void {
    if (this.showLocationsOverAirport()) {
      if (this.locations![0].showPrice)
        this.getPrice(this.locations![0], 1)

      if (this.locations![1].showPrice)
        this.getPrice(this.locations![1], 2)
    }
    else {
      if (this.airport)
        this.getPrice(this.airport, 0);
      if (this.locations && this.locations[0] && this.locations[0].showPrice)
        this.getPrice(this.locations[0], 1)
    }
  }

  scheduleTasks(): void {
    setInterval(() => {
      const now = new Date();
      const currentHour = now.getHours();

      // Define your specific hours array
      const specificHours = [6, 12, 17, 21];

      // Check if current hour is in the list of specific hours and minutes is 0
      if (specificHours.includes(currentHour) && now.getMinutes() === 0)
        this.setPrices();

    }, 60000); // Check every minute
  }

  getPrice(destination: IDestination, value: number) {
    const getPriceRequest: GetPriceRequest = {
      hotelId: this.hotelId!,
      accessToken: this.accessToken!,
      from: this.from!,
      to: destination,
      pickupTime: new Date(),
      orderType: OrderType.Mobile,
      largeTaxi: false,
      paymentOption: PaymentOption.PayInTaxi,
      numberOfCars: 1
    };

    this.getTaxiService.getTaxiPrice(getPriceRequest).subscribe((result: IPriceInfo) => {
      this.currency = result.currency;
      let price = result.customerPrice ?? result.taxiPrice!;
      if (value == 0)
        this.airportPrice = price
      if (value == 1)
        this.firstPrice = price
      if (value == 2)
        this.secondPrice = price
    });

    return 0;
  }

  continue(value: number): void {
    this.dialogRef.close(value);
  }

  getWhatToDisplay(): number {
    if (this.logoSrc == 'assets/images/logo_white.svg') //Default
      return 0;
    else if (this.logoSrc == 'assets/images/nt_x_hr.png') //Norway
      return 1;
    else return 0;
  }

  getLocationTranslation(number: number): string {
    return this.translate.instant('selfServiceStation_orderTaxiSpecial').replace('{nameOfLocation}', this.locations?.[number].name)
  }

  getAirportPrice(): string {
    if (this.airportPrice)
      return this.airportPrice?.toString() + " " + this.currency;
    return '';
  }

  getLocationPrice(value: number): string {
    if (value == 0 && this.firstPrice)
      return this.firstPrice?.toString() + " " + this.currency;
    else if (value == 1 && this.secondPrice)
      return this.secondPrice?.toString() + " " + this.currency;
    else return ''
  }

  showLocationsOverAirport(): number {
    if (this.locations?.length === 2) {
      const hasEmptyNameAndAddress = this.locations.some(
        location => !location.name && !location.address
      );

      if (hasEmptyNameAndAddress)
        return 2;
      else
        return 1;
    }
    else
      return 0;
  }

  updateComponentData(from: IDestination, airport: IDestination, logoSrc: string | undefined, specialLocations: IHotelSpecialLocations[] | undefined) {
    this.from = from;
    this.airport = airport;
    this.logoSrc = logoSrc;

    if (specialLocations && specialLocations.length != 0)
      this.locations = specialLocations;

    if (this.from && (this.airport || (this.locations && this.locations.length != 0))) {
      this.setPrices();
      this.scheduleTasks();
    }
  }
}
