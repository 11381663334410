import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { IPriceInfo } from 'src/app/services/models/price-info.model';
import { PriceService } from 'src/app/services/price.service';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss']
})
export class PriceComponent implements OnInit, OnDestroy {
  @Input() textWhenPriceIsUnknown: string = '';
  @Input() textWhenPriceIsKnown: string = '';

  @Output() loadingPriceEmitter = new EventEmitter<boolean>();

  priceInfo?: IPriceInfo;
  loadingTaxiPrice?: boolean;

  text?: string;

  taxiPriceSubscription: Subscription;
  loadingTaxiPriceSubscription: Subscription;
  textWhenPriceIsUnknownSubscription?: Subscription;
  languageChangeSubscription?: Subscription;

  constructor(priceService: PriceService, private translate: TranslateService) {
    this.taxiPriceSubscription = priceService.taxiPrice.subscribe(p => {
      this.priceInfo = p;
      this.updateText();
    });

    this.loadingTaxiPriceSubscription = priceService.loadingTaxiPrice.subscribe(l => {
      this.loadingTaxiPrice = l;
      this.updateText();
    });

    this.languageChangeSubscription = this.translate.onDefaultLangChange.subscribe(() => this.updateText());
  }

  ngOnInit(): void {
    this.loadingTaxiPrice = true;

    this.updateText();
  }

  updateText() {
    if (this.loadingTaxiPrice) {
      this.loadingPriceEmitter.emit(true);
    }
    else if (!this.priceInfo?.customerPrice) {
      this.loadingPriceEmitter.emit(false);
      this.textWhenPriceIsUnknownSubscription?.unsubscribe();
      this.textWhenPriceIsUnknownSubscription = this.translate.get(this.textWhenPriceIsUnknown).subscribe(text => this.text = text);
    }
    else {
      this.loadingPriceEmitter.emit(false);
      this.text = (this.priceInfo?.customerPrice ? this.translate.instant(this.textWhenPriceIsKnown) : this.translate.instant(this.textWhenPriceIsUnknown))
        .replace('{price}', this.priceInfo?.customerPrice)
        .replace('{currency}', this.priceInfo?.currency);
    }
  }

  ngOnDestroy(): void {
    this.taxiPriceSubscription.unsubscribe();
    this.loadingTaxiPriceSubscription.unsubscribe();
    this.textWhenPriceIsUnknownSubscription?.unsubscribe();
    this.languageChangeSubscription?.unsubscribe();
  }
}