<div class="dashboard-container">
    <div class="dashboard-header">
        <h2>Dashboard</h2>
    </div>
    <div class="dashboard-content">
        <div class="dashboard-item">
            <p class="label">{{'arrivalTimeAverageTitle' | translate}}</p>
            <p class="value" *ngIf="averageTime">{{ this.averageTime }} min.</p>
            <p class="value" *ngIf="!averageTime">{{ "arrivalTimeAverageNoTime" | translate }}</p>
        </div>
        <div class="dashboard-item">
            <p class="label">{{ 'rating' | translate }}</p>
            <p class="value" *ngIf="rating">{{ rating }}</p>
            <p class="value" *ngIf="!rating">{{ "noRatingFound" | translate }}</p>
        </div>
        <div class="dashboard-item" *ngIf="displayBasicCounter || displayCoinCounter">
            <p class="label">{{ "total" | translate }}</p>
            <p class="value" *ngIf="displayBasicCounter">{{ getEarnedCoins() }} {{ currency }}</p>
            <p class="value" *ngIf="displayCoinCounter">{{ getEarnedCoins() }} EUR.</p>
        </div>
        <div class="dashboard-bonus" *ngIf="displayProgressBar">
            <p class="bonus-label">{{getMonthlyBonusGoalTitle()}}</p>
            <div class="progress-bar-container">
                <div class="header">
                    <span [ngClass]="{'text-white': isWhiteTextColor}" class="left-text">{{ getEarnedCoinsMonthly() }}</span>
                    <span [ngClass]="{'text-white': isWhiteTextColor}" class="right-text">{{ monthlyRidesGoal }}</span>
                </div>
                <mat-progress-bar [mode]="'determinate'" [value]="getProgressPercentage()" class="progress-bar"></mat-progress-bar>
            </div>
        </div>
        <div class="christmas-div" *ngIf="showChristmasImage">
            <img class="christmas-image" src="assets/images/christmas.gif">
            <p class="christmas-text">Merry Christmas from HotelRide!</p>
        </div>
    </div>
</div>