<div class="pause-overlay" *ngIf="isVisible">
    <app-header [language]="language"></app-header>
    <div class="pause-message">
        <img class="image" [src]='logoSrc'>
        <button (click)="resume()">{{ "pausescreen-button" | translate }}</button>
    </div>
    <div class="taxi-image">
        <img class="logo" [src]="taxiLogo">
    </div>
    <app-bottom-banner [logoSrc]="logoSrc"></app-bottom-banner>
</div>
  