import { Subscription } from 'rxjs';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HotelService } from 'src/app/services/hotel.service';
import { ICoordinate } from 'src/app/models/coordinate.interface';

@Component({
    selector: 'app-google-map',
    templateUrl: './google-map.component.html',
    styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent implements OnInit, OnDestroy, OnChanges {
    @Input() carCoordinates: ICoordinate[] = [];
    @Input() markerPosition: ICoordinate | undefined;
    @Input() width: string = '500px';
    @Input() height: string = '445px';

    markers: any;
    center: any;
    zoom = 12;
    mapOptions = { maxZoom: 15 };
    fromLocation: any;
    longitudeSubscription?: Subscription;

    constructor(private translate: TranslateService, private hotelService: HotelService) {
        this.markers = [];
    }

    ngOnInit(): void {
        this.longitudeSubscription = this.hotelService.longitude.subscribe(_ => this.updatePosition());
        this.updatePosition();
    }

    ngOnDestroy(): void {
        this.longitudeSubscription?.unsubscribe();
    }

    updatePosition() {
        this.getPosition().then(pos => {
            if (pos.lat === 0)
                return;

            this.fromLocation = { lat: pos.latitude, lng: pos.longitude };
            this.markers.push({ position: this.fromLocation });
            this.center = {
                lat: pos.latitude,
                lng: pos.longitude,
            };
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updatePosition();

        this.markers = [];
        this.markers.push({ position: this.fromLocation });
        if (this.carCoordinates) {
            this.carCoordinates.forEach(c => {
                const additionalInfo = c.additionalGuestInfo ? `(${c.additionalGuestInfo}) ` : '';
                this.markers.push({
                    position: {
                        lat: c.lat,
                        lng: c.lng,
                    },
                    options: {
                        icon: '../../../assets/images/taxi-soft.svg',
                        label: {
                            className: 'marker-position',
                            color: '#000000',
                            fontWeight: 'bold',
                            fontSize: '14px',
                            text: additionalInfo + c.minutesAway + this.translate.instant('googleMapTaxiText'),
                        }
                    },
                });
            });
        }
    }

    getPosition(): Promise<any> {
        var posistionResolve = { longitude: this.hotelService.longitude.value, latitude: this.hotelService.latitude.value };

        if (this.markerPosition !== undefined)
            posistionResolve = { longitude: this.markerPosition.lng, latitude: this.markerPosition.lat };

        return new Promise((resolve, reject) => {
            resolve(posistionResolve);
        });
    }
}