<ion-content id="bookTaxi" class="new" *ngIf="splitTest == 2">
  <app-new-header [logoSrc]="logoSrc" [name]="showName"></app-new-header>
  <div class="taxiHotel">
    <div class="top-line">
      <div class="destination">
        {{ "anotherDestination" | translate }}
      </div>
      <div class="dropdown">
        <button class="dropbtn" (click)="toggleDropdown()">
          {{ "taxi-rides" | translate }} & {{ "taxi-arrivals" | translate }} {{ getHasRides() }}
        </button>
        <div class="dropdown-content" [ngClass]="{'hide': !dropDownOpen}">
          <app-new-upcoming-rides (newRideDetected)="showDropdown($event)" 
            (rideSelected)="onRideSelected($event)"></app-new-upcoming-rides>
        </div>
      </div>    
    </div>
    <div class="center">
      <div class="left-container">
        <form class="orderForm">
            <app-reception-platform></app-reception-platform>
        </form>
      </div>      
      <div class="right-container">
        <div class="google-map">
          <app-new-google-map height="85vh" width="47vw" [carCoordinates]="taxiCars" [markerId]="markerId"></app-new-google-map>
        </div>
      </div>
    </div>
  </div>
</ion-content>

<ion-content id="bookTaxi" class="old" *ngIf="splitTest == 1">
  <app-header [logoSrc]="logoSrc" [language]="hotelInfo?.language" [name]="showName"></app-header>
  <div class="taxiHotel">
    <div class="center">
      <div class="left-container">
        <app-cancelled-rides-applytobill-remove *ngIf="showCancelledApplyToBill"></app-cancelled-rides-applytobill-remove>
        <app-average-time *ngIf="getDisplayAverage()"></app-average-time>
        <app-rating *ngIf="getDisplayRating()"></app-rating>
        <app-coin-tree-counter-container></app-coin-tree-counter-container>
      </div>
      <div class="main-container">

        <div class="tabs">
          <mat-button-toggle-group name="fontStyle"
                                   aria-label="Font Style"
                                   value="destination">
            <mat-button-toggle value="destination"
                               (click)="otherDestination()">
              {{ "anotherDestination" | translate }}
            </mat-button-toggle>

            <mat-button-toggle value="fromairport" (click)="fromAirport()">
              {{ "fromAirport" | translate }}
            </mat-button-toggle>

            <button mat-button class="mat-button" (click)="sendLinkPopupActivator()">
              {{ "sendPaymentLinkToCustomer_tabText" | translate }}
            </button>

            <mat-button-toggle (click)="taxiSupport()">
              {{ "taxiSupport" | translate }}
            </mat-button-toggle>

            <mat-button-toggle class="big-screen simple-button ml"
                               disabled>
              {{ "taxi-rides" | translate }} & {{ "taxi-arrivals" | translate }}
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <app-taxi-status-message></app-taxi-status-message>

        <div class="site-content">
          <form class="orderForm">
            <div class="container">
              <app-second-destination *ngIf="otherDestinationTab"></app-second-destination>
              <app-airport *ngIf="toAirportTab"></app-airport>
              <app-from-airport *ngIf="fromAirportTab"></app-from-airport>
              <app-taxi-contact *ngIf="taxiSupportTab"></app-taxi-contact>
            </div>
          </form>
          <div class="right-side">
            <div>
              <app-upcoming-rides class="big-screen"></app-upcoming-rides>
            </div>
            <div class="google-map">
              <app-google-map [carCoordinates]="taxiCars"></app-google-map>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>