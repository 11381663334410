import { Component, forwardRef, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatRadioGroup } from '@angular/material/radio';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GooglePlaceDirective } from 'src/app/directives/google-place/google-place.directive';
import { Address } from 'src/app/directives/google-place/objects/address';
import { ComponentRestrictions } from 'src/app/directives/google-place/objects/options/componentRestrictions';
import { Options } from 'src/app/directives/google-place/objects/options/options';
import { SearchEngine } from 'src/app/models/enums';
import { IHotelInfo } from 'src/app/models/hotel-info.interface';
import { GetTaxiService } from 'src/app/services/get-taxi.service';
import { HotelService } from 'src/app/services/hotel.service';
import { GetAddressRequest } from 'src/app/services/models/locations.model';
import { IDestination } from 'src/app/shared/models/destination.interface';

@Component({
  selector: 'app-new-destination-selector',
  templateUrl: './new-destination-selector.component.html',
  styleUrls: ['./new-destination-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NewDestinationSelectorComponent),
      multi: true
    }
  ]
})
export class NewDestinationSelectorComponent implements OnInit, OnDestroy {
  @Input() showErrors = false;
  @Input() fromHotel: boolean = true;
  @Input() disabled: boolean = false;
  @Input() rounding: boolean = false;
  @Input() searchEngine: SearchEngine = SearchEngine.Google;

  private _hotelInfo = new Subject<any>();
  @Input()
  set hotelInfo(value: IHotelInfo | undefined) {
    this._hotelInfo.next(value);  // Emit new value to subscribers
  }
  private destroy$ = new Subject<void>();

  suggestionsShown = false;
  alternativeShown = false;

  fixedDestinations: any;
  alternativeDestinations: any;
  getAddressRequest: GetAddressRequest = { latitude: 0, longitude: 0, searchQuery: "" };

  location?: IDestination;
  destinationName = '';

  onChange: any = () => { };
  onTouched: any = () => { };

  destinationSearchCountriesSubscription?: Subscription;

  @ViewChild(GooglePlaceDirective) inputField?: GooglePlaceDirective;
  @ViewChild('standardSuggestionsElement') standardSuggestionsElement?: MatRadioGroup;
  @ViewChild('alternativeSuggestionsElement') alternativeSuggestionsElement?: MatRadioGroup;

  inputFieldOptions = new Options();

  constructor(private hotelService: HotelService, private taxiService: GetTaxiService) { }

  ngOnInit(): void {
    this.destinationSearchCountriesSubscription = this.hotelService.destinationSearchCountries.subscribe(countries => {
      if (countries.length > 0) {
        this.inputFieldOptions = {
          fields: ['name', 'formatted_address', 'address_components'],
          componentRestrictions: new ComponentRestrictions({
            country: countries
          })
        };

        // The destinationOptions should be set before we can initialize the autocomplete component
        // and this requires us to wait a bit :-/
        setTimeout(() => this.inputField?.initialize(), 250);
      }
    });

    // Phillip and Henrik decided to hide the list of fixed destinations
    // this.taxiService.getFixedDestinationsExcludingAirports().subscribe(destinations => {
    //   this.fixedDestinations = destinations.map((d: any) => ({ name: d.destinationName, address: d.destination }));
    // });

    this.handleSuggestionsElementChange({
      name: "",
      address: this.fromHotel ? "" : "",
    })

    this._hotelInfo
      .pipe(takeUntil(this.destroy$)) // Automatically unsubscribe on destroy
      .subscribe(newValue => {
        this.getAddressRequest.latitude = newValue.latitude
        this.getAddressRequest.longitude = newValue.longitude
      });
  }

  ngOnDestroy(): void {
    this.destinationSearchCountriesSubscription?.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  handleInputFieldAddressChange(address: Address) {
    if (!address.formatted_address || !address.name) return;

    this.location = {
      name: address.name,
      address: address.formatted_address,
    };
    this.destinationName = address.name;

    this.onChange(this.location);
  }

  inputFieldFocused() {
    this.inputFieldKeyUp({});
  }

  handleSuggestionsElementChange(address: { name: string, address: string }) {
    this.location = {
      name: address.name,
      address: address.address
    }

    this.destinationName = address.name;

    this.onChange(this.location);
    this.hideSuggestions();
  }

  handleInputChange(event: any) {
    if (this.searchEngine == SearchEngine.Google)
      return;

    this.getAddressRequest.searchQuery = event.target.value;

    if (this.getAddressRequest.searchQuery.length < 3)
      return;

    this.taxiService.getLocationsBasedOnAddress(this.getAddressRequest).subscribe(async (data) => {
      this.alternativeDestinations = data.map((d: any) => ({ name: d.name, address: d.address }));;
      this.showSuggestions();
    }, async (error) => { })
  }

  showSuggestions() {
    // Phillip and Henrik decided to hide the list of fixed destinations
    //this.suggestionsShown = true;
    this.alternativeShown = this.getShowAlternative();
  }

  hideSuggestions() {
    this.suggestionsShown = false;
    this.alternativeShown = false;
  }

  getShowAlternative(): boolean {
    return this.searchEngine != SearchEngine.Google;
  }

  suggestionsElementKeyDown(keyEvent: any) {
    if (keyEvent.key === 'Enter') {
      this.hideSuggestions();
      // Focus the input field
      (<any>this.inputField).el.nativeElement.focus();

      keyEvent.cancelBubble = true;
      return false;
    }

    if (keyEvent.key === 'Tab')
      this.hideSuggestions();

    return true;
  }

  inputFieldKeyUp(keyEvent: any) {
    let currentValue = (<any>this.inputField)?.el.nativeElement.value;
    if (currentValue)
      this.hideSuggestions();
    else {
      this.location = { name: '', address: '' };
      this.destinationName = '';

      this.onChange(this.location);

      this.showSuggestions();
    }
  }

  writeValue(obj: { name: string, address: string } | undefined): void {
    if (!obj) {
      this.destinationName = '';
      return;
    }

    this.destinationName = obj.name;
    this.location = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;

    this.onChange(this.destinationName);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  focusUsingMouse() {
    this.hideSuggestions();
  }

  private clickWasInsideThisComponent = false;

  @HostListener('click')
  clickInsideThisComponent() {
    this.clickWasInsideThisComponent = true;
    this.showSuggestions()
  }

  @HostListener('document:click', ['$event'])
  clickOutsideComponent() {
    if (!this.clickWasInsideThisComponent)
      this.hideSuggestions();

    this.clickWasInsideThisComponent = false;
  }
}