<div class="header">
    <div *ngIf="showName()" class="name">
        <strong>{{ name }}</strong>
    </div>
    <div class="overlay">
        <img *ngIf="isMobile" class="mobile-logo" [src]="logoSrc"/>
        <img *ngIf="isHotel" class="hotel-logo" [src]="logoSrc"/>
        <app-new-top-menu *ngIf="isHotel"></app-new-top-menu>
        <img *ngIf="gdpr" (click)="openGDPRPopUp()" class="gdpr" src="../../../../assets/images/gdpr.png">
        <div class="side-buttons">
            <button mat-button class="button" (click)="customerSupportPopUp()">
                {{ "taxiSupport" | translate }}
            </button>
            <div class="supportMail">
                <a href="mailto:support@commuteapp.dk">Support mail</a>
            </div>
        </div>
        <app-new-language-chooser></app-new-language-chooser>
    </div>
</div>
