import { Subscription } from 'rxjs';
import { Component, Input, OnDestroy } from '@angular/core';
import { PaymentOption } from 'src/app/services/hotel.service';
import { PriceService } from 'src/app/services/price.service';
import { RideType } from 'src/app/models/enums';

@Component({
  selector: 'app-new-order-button',
  templateUrl: './new-order-button.component.html',
  styleUrls: ['./new-order-button.component.scss']
})
export class NewOrderButtonComponent implements OnDestroy {
  @Input() paymentOption: PaymentOption = PaymentOption.ApplyToBill;

  usingFixedPrices = false;

  priceFromTaxiCompanySubscription?: Subscription;

  constructor(private priceService: PriceService) {
    this.priceFromTaxiCompanySubscription = priceService.priceFromTaxiCompany.subscribe(_ => this.setUsingFixedPrices());
  }

  ngOnDestroy(): void {
    this.priceFromTaxiCompanySubscription?.unsubscribe();
  }

  setUsingFixedPrices() {
    this.usingFixedPrices = this.priceService.rideType.value == RideType.FixedPrice || this.priceService.priceFromTaxiCompany.value;
  }

  isApplyToBill() {
    return this.paymentOption === PaymentOption.ApplyToBill;
  }

  isHotelPayment() {
    return this.paymentOption === PaymentOption.HotelPays;
  }

  isPaidInTaxi() {
    return this.paymentOption === PaymentOption.PayInTaxi;
  }

  isPrepaid() {
    return this.paymentOption === PaymentOption.Prepay;
  }

  isPayByTerminal() {
    return this.paymentOption === PaymentOption.PayByTerminal;
  }

  showPrice() {
    return this.priceService.showPrice || (this.isPaidInTaxi() && this.priceService.isFixedDestination == true);
  }
}
