import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { TaxiService } from './taxi.service';
import { ISaveOtherDestinationTerminalRequest } from '../models/ride/save-otherdestination-terminal.interface';
import { ISaveOtherDestinationRequest } from '../models/ride/save-otherdestination.interface';
import { IDirectTaxiRequest } from '../models/ride/directtaxi.interface';
import { IFromAirportRideRequest } from '../models/ride/from-airport.interface';

@Injectable({
  providedIn: 'root'
})
export class SaveTaxiService extends TaxiService {
  public directTaxiRide(body: IDirectTaxiRequest): Observable<any> {
    return this.http.post(this.api_url + environment.directTaxiRide, body);
  }

  public saveOtherDestinations(body: ISaveOtherDestinationRequest): Observable<any> {
    return this.http.post(this.api_url + environment.saveOtherDestinations, body);
  }

  public saveOtherDestinationTerminal(body: ISaveOtherDestinationTerminalRequest): Observable<any> {
    return this.http.post(this.api_url + environment.saveOtherDestinationTerminal, body);
  }

  public saveTaxiToAirport(body: any): Observable<any> {
    return this.http.post(this.api_url + environment.saveTaxiToAirport, body);
  }

  public saveTaxiToHotel(body: IFromAirportRideRequest): Observable<any> {
    return this.http.post(this.api_url + environment.saveTaxiToHotel, body);
  }

  public updateFixedDestinations(fixedDestinations: any): Observable<any> {
    return this.post(environment.updateFixedDestinations, { fixedDestinations }, true, true);
  }

  public createFixedDestinations(fixedDestinations: any): Observable<any> {
    return this.post(environment.createFixedDestinations, fixedDestinations, true, true);
  }

  public deleteFixedDestinations(ids: string[]): Observable<any> {
    return this.post(environment.deleteFixedDestinations, { ids: ids }, true, true);
  }
}
