<div class="action">
    <app-new-price [hidden]="!showPrice()">
    </app-new-price>

    <button mat-flat-button color="secondary" type="submit" class="m-r-10">
        <span>{{ 'orderTaxiButton' | translate }}</span>
        
        <!--
        <app-price-as-text [hidden]="!isPayByTerminal()"></app-price-as-text>

        <div [hidden]="!isApplyToBill()">{{ 'taxiPriceApplyToBill' | translate }}</div>

        <div [hidden]="!isHotelPayment()">{{ 'taxiPricePaidByHotel' | translate }}</div>

        <div [hidden]="!isPaidInTaxi()">{{ 'taxiPricePaidInTaxi' | translate }}</div>

        <div [hidden]="!isPrepaid()">{{ 'taxiPricePrepaid' | translate }}</div>
        -->
    </button>
</div>